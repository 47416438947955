<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col
                  cols="12"
                  md="2"
                  class="mt-2"
                >
                  <b-form-checkbox
                    v-model="forYear"
                    class="mt-0"
                    name="forYear"
                    switch
                    ize="lg"
                    @change="checkForYear"
                  >
                    Theo năm
                  </b-form-checkbox>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Hệ đào tạo"
                    label-for="trainingSystemId"
                  >
                    <v-select
                      v-model="filter.trainingSystemId"
                      :options="trainingSystems"
                      :reduce="option => option.value"
                      @input="onTrainingSystemsChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label="Khóa học"
                    label-for="courseId"
                  >
                    <v-select
                      v-model="filter.courseId"
                      :options="courses"
                      :reduce="option => option.value"
                      @input="onCoursesChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  v-if="forYear"
                >
                  <b-form-group
                    label="Năm học"
                    label-for="yearId"
                  >
                    <v-select
                      v-model="filter.yearId"
                      :options="yearsByCourse"
                      :reduce="option => option.value"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  v-if="!forYear"
                >
                  <b-form-group
                    label="Học kỳ"
                    label-for="courseSemesterId"
                  >
                  <v-select
                    v-model="filter.courseSemesterId"
                    :options="courseSemesters"
                    :reduce="option => option.value"
                    placeholder="Học kỳ"
                  />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Khoa/Bộ môn"
                    label-for="departmentId"
                  >
                    <v-select
                      v-model="filter.departmentId"
                      :options="departments"
                      :reduce="option => option.value"
                      @input="onDepartmentChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Ngành học"
                    label-for="majorId"
                  >
                    <v-select
                      v-model="filter.majorId"
                      :options="majors"
                      :reduce="option => option.value"
                      @input="onMajorChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Lớp cố định"
                    label-for="classId"
                  >
                    <v-select
                      v-model="classId"
                      :options="classes"
                      :reduce="option => option.value"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <div
                    style="padding-top: 20px;"
                  >
                    <b-button
                      v-show="classId > 0"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-1"
                      @click="onSelect"
                    >
                      Danh sách
                    </b-button>
                    <b-button
                      v-show="classId > 0"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      @click="exportExcel"
                    >
                      Xuất excel
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
            <b-row class="mt-2">
              <b-col cols="12">
                <div>
                  <vue-good-table
                    mode="remote"
                    :columns="columns"
                    :rows="dataLists"
                    :pagination-options="paginationOptions"
                    :line-numbers="true"
                    style-class="vgt-table condensed bordered"
                  >
                    <div
                      slot="emptystate"
                      style="text-align: center; font-weight: bold"
                    >
                      Không có bản ghi nào !
                    </div>
                    <template
                      slot="table-row"
                      slot-scope="props"
                    >
                      <span v-if="props.column.field === 'stt'">
                        {{ props.row.originalIndex + 1 }}
                      </span>
                      <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                    </template>
                  </vue-good-table>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <validation-observer
      ref="updateGraduationTypeFormRef"
      #default="{invalid}"
    >
      <b-modal
        id="updateGraduationTypeModal"
        ref="updateGraduationTypeModal"
        title="Cập nhật hình thức làm khóa luận"
      >
        <b-form-group label-for="trainingSystemId">
          <template v-slot:label>
            Hình thức làm khóa luận <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{errors}"
            name="Hình thức làm khóa luận"
            rules="required"
          >
            <v-select
              v-model="graduation_type_id"
              :options="graduationType"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave()"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Lưu
              </span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('updateGraduationTypeModal')"
            >
              <span class="text-right">
                <feather-icon icon="XIcon" /> Hủy
              </span>
            </b-button>
          </div>
        </template>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BOverlay,
  BRow, BFormGroup, BForm, VBTooltip, BFormInvalidFeedback, BFormCheckbox
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/extensions
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import * as XLSX from 'xlsx'

export default {
  name: 'LearningGrading',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BCard,
    BCol,
    BContainer,
    BOverlay,
    BRow,
    BFormGroup,
    BForm,
    vSelect,
    VueGoodTable,
    BFormCheckbox
  },
  data() {
    return {
      isLoading: false,
      graduation_type_id: undefined,
      selectedItem: undefined,
      filter: {
        currentPage: 1,
        itemsPerPage: 1000,
        organizationId: '',
        trainingSystemId: '',
        courseId: '',
        yearId: '',
        courseSemesterId: '',
        departmentId: '',
        majorId: '',
        programmeId: '',
        subjectId: '',
        status: '',
        sort: '',
      },
      classId: null,
      paginationOptions: {
        enabled: false,
      },
      columns: [
        {
          label: 'Mã sinh viên',
          field: 'student_code',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Họ và tên',
          field: 'full_name',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Ngày sinh',
          field: 'birthday',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Điểm hệ số 10 trong kỳ',
          field: 'gpa_tenth',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Học lực',
          field: 'hoc_luc',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      forYear: false
    }
  },
  computed: {
    ...mapGetters({
      dataLists: 'graduationCondition/dataLists',
      classes: 'graduationCondition/classes',
      courses: 'dropdown/courses',
      trainingSystems: 'dropdown/trainingSystems',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      graduationType: 'graduationCondition/graduationType',
      courseSemesters: 'dropdown/courseSemesters',
      years: 'dropdown/courseYears',
      yearsByCourse: 'dropdown/yearsByCourse'
    }),
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.GRADUATION_CLASS)
    },
  },
  async created() {
    this.isLoading = true
    try {
      const cUser = getUser()
      this.filter.organizationId = (cUser.orgId ? cUser.orgId : 0)
      await Promise.all([
        this.getTrainingSystems({ organizationId: this.filter.organizationId }),
        this.getDepartments({ organizationId: this.filter.organizationId }),
      ])
      if (this.trainingSystems.length > 0) {
        this.filter.trainingSystemId = this.trainingSystems[0].value
        await this.getCourses(this.filter)
      }
      this.$store.commit('graduationCondition/SET_DATA', { records: [] })
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getData: 'graduationCondition/getDataAcademicPerformanceGrading',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getClasses: 'graduationCondition/getClasses',
      updateGraduationType: 'graduationCondition/updateGraduationType',
      setGraduationClass: 'graduationCondition/setGraduationClass',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getYearByCourse: 'dropdown/getYearByCourse'
    }),
    async onTrainingSystemsChange(event) {
      this.filter.trainingSystemId = event
      this.filter.courseId = null
      await this.getCourses(this.filter)
    },
    async onCoursesChange(event) {
      this.filter.courseId = event
      this.filter.courseSemesterId = null
      await Promise.all(
        [
          this.getCourseSemesters({ organizationId: getUser().orgId, courseId: event }),
          this.getYearByCourse({courseId: event})
        ]
      )
    },
    async onDepartmentChange(event) {
      this.filter.departmentId = event
      this.filter.majorId = null
      await this.getMajors(this.filter)
    },
    async onMajorChange(event) {
      this.filter.majorId = event
      this.filter.programmeId = null
      await this.getClasses(this.filter)
    },
    async onSelect() {
      this.isLoading = true
      try {
        await this.getData({
          class_id: this.classId,
          course_semester_id: this.filter.courseSemesterId,
          year_id: this.filter.yearId
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

    async onSave() {
      const valid = this.$refs
        .updateGraduationTypeFormRef
        .validate()
      if (valid) {
        try {
          await this.updateGraduationType({ student_class_id: this.selectedItem, graduation_type: this.graduation_type_id })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cập nhật hình thức làm khóa luận thành công!',
              icon: 'XCircleIcon',
              variant: 'success',
            },
          })
          this.graduation_type_id = undefined
          await this.getData({
            class_id: this.classId,
          })
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.$bvModal.hide('updateGraduationTypeModal')
        }
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    checkForYear() {
      if (this.forYear) {
        this.filter.courseSemesterId = ''
      }else {
        this.filter.yearId = ''
      }
    },

    exportExcel() {
      if (this.dataLists.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      const data = []
      const header = {}
      this.columns.forEach(column => {
        header[column.field] = column.label
      })
      const headerKeys = Object.keys(header)
      const dataSourceKeys = Object.keys(this.dataLists[0])
      const duplicates = dataSourceKeys.filter(val => headerKeys.includes(val))
      duplicates.sort((a, b) => headerKeys.indexOf(a) - headerKeys.indexOf(b))
      this.dataLists.forEach(dataSource => {
        const elm = {}
        duplicates.forEach(key => {
          elm[header[key]] = dataSource[key]
        })
        data.push(elm)
      })
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'SinhVien')
      XLSX.writeFile(wb, 'sinhvien.xlsx')
    },
  },
}
</script>
